/**
 * Redux Store, Actions, and Reducer to manage global state.
 */

import { Entity, Preferences, Site } from './types';
import { loadLocal, saveLocal } from './utils';

// Local storage key for the limited Site IDs
const KEY_LIMITED_SITES = 'sites.limited';
// Local storage key for the user preferences
const KEY_USER_PREFERENCES = 'user.prefs';

const DEFAULT_PREFERENCES: Preferences = {
  shortEntityId: true,
  todayTime: false,
  confirmClaim: true,
  newEntityBeep: true,
}

// STORE //////////////////////////////////////////////////////////////

/** Redux store */
export type StoreState = {
  /** The current logged-in user */
  awsUser: any;
  /** Known entities */
  entities: Entity[];
  /** Known sites */
  sites: Site[];
  /** Known sites, mapped by site ID */
  sitesMap: Record<string, Site>;
  /** Selected site IDs */
  limitedSites: string[] | null;
  /** GUI Errors */
  errors: any[];
  /** Busy sempahore */
  busyCount: number;
  /** Is the GUI busy? */
  isBusy: boolean;
  /** User Preferences */
  prefs: Preferences;
}

/** Initial state of the store */
const initState: StoreState = {
  awsUser: null,
  entities: [],
  sites: [],
  sitesMap: {},
  limitedSites: loadLocal(KEY_LIMITED_SITES),
  errors: [],
  busyCount: 0,
  isBusy: false,
  prefs: {
    // Merge the default and saved preferences
    ...DEFAULT_PREFERENCES,
    ...(loadLocal(KEY_USER_PREFERENCES) ?? {}),
  }
}

// ACTIONS ////////////////////////////////////////////////////////////

/**
 * Set the current AWS user
 */
export const setAwsUser = (user: any) => ({
  type: 'SET_AWS_USER' as const,
  data: user,
});

/**
 * Set the entire list of entities
 */
export const setEntities = (entities: Entity[]) => ({
  type: 'ENTITIES_SET' as const,
  data: entities,
});

/**
 * Update an entity
 */
export const updateEntity = (entity: Entity) => ({
  type: 'ENTITY_UPDATE' as const,
  data: entity,
});

/**
 * Delete an entity by ID
 */
export const deleteEntity = (entity_id: string) => ({
  type: 'ENTITY_DELETE' as const,
  data: entity_id,
});

/**
 * Set the entire list of sites
 */
export const setSites = (sites: Site[]) => {
  // Build the sites lookup table
  const sitesMap: Record<string, Site> = {};
  sites.forEach(s => sitesMap[s.siteId] = s);

  return {
    type: 'SITES_SET' as const,
    data: { sites, sitesMap }
  };
};

/**
 * Select site IDs for filtering
 */
export const limitSites = (siteIds: string[] | null) => {
  // Save to local storage
  saveLocal(KEY_LIMITED_SITES, siteIds);

  return {
    type: 'SITES_LIMIT' as const,
    data: siteIds,
  };
};

/**
 * Update the User Preferences
 */
export const updatePrefs = (prefs: Preferences) => {
  // Save to local storage
  saveLocal(KEY_USER_PREFERENCES, prefs);

  return {
    type: 'PREFS_SET' as const,
    data: prefs,
  };
};

// Record a new error
export const addError = (error: any) => {
  // Start with the basic item
  const item: any = {
    text: null,
    title: null,
    error
  };

  if (!error) {
    item.text = 'Unknown error';
  }
  else if (typeof error === 'string') {
    // Just use the text
    item.text = error;
  }
  else if (error.response) {
    // API error
    const status = error.response.status;

    item.title = `API ERROR [${status}]`;

    // Fallback to a generic (and useless) message
    item.text = 'Server error';
    if (error.response.data?.Message) {
      // Amplify Error
      item.text = error.response.data.Message;
    }
    else if (error.response.data?.detail) {
      // The API has provided an error message
      const detail = error.response.data?.detail
      if (typeof detail === 'string') {
        // Simple message
        item.text = detail;
      }
      else if ((status === 422) && Array.isArray(detail)) {
        // Validation errors are sent as arrays
        item.text = 'Validation Error:\n' + detail.map(item => `\t${item.msg}: ${item.loc.join('.')}`).join('\n');
      }
      else {
        // Not sure what this is, but let's make it at least sort of readable and not '[Object object]'
        item.text = JSON.stringify(detail);
      }
    }
  }
  else if (typeof error.message === 'string') {
    // Get the Error message
    item.text = error.message;
  }
  else {
    // Hmm, something else
    item.text = error.toString();
  }

  return {
    type: 'ERRORS_ADD' as const,
    error: item
  };
};

// Dismiss the current error
export const dismissError = () => ({
  type: 'ERRORS_REMOVE' as const
});

// Clear all errors
export const clearErrors = () => ({
  type: 'ERRORS_CLEAR' as const,
});

// Set the busy state of the application
export const setBusy = (busy: boolean) => ({
  type: 'BUSY_SET' as const,
  data: busy,
});

// All possible Redux actions
export type Action = 
  ReturnType<typeof setAwsUser> |
  ReturnType<typeof setEntities> |
  ReturnType<typeof updateEntity> |
  ReturnType<typeof deleteEntity> |
  ReturnType<typeof setSites> |
  ReturnType<typeof limitSites> |
  ReturnType<typeof updatePrefs> |
  ReturnType<typeof addError> |
  ReturnType<typeof dismissError> |
  ReturnType<typeof clearErrors> |
  ReturnType<typeof setBusy>
  ;


// REDUCER ////////////////////////////////////////////////////////////

// Core reducer for the redux store
export default function reduxReducer(state = initState, action: Action): StoreState {
  switch (action.type) {
    case 'SET_AWS_USER':
      return {
        ...state,
        awsUser: action.data,
      };

    case 'ENTITIES_SET':
      // Set the entire list of entities
      return {
        ...state,
        entities: action.data,
      };
    case 'ENTITY_UPDATE':
      // Replace the single entity 
      return {
        ...state,
        entities: [
          ...state.entities.filter(e => e.entityId !== action.data.entityId),
          action.data
        ]
      };
    case 'ENTITY_DELETE':
      // Delete the single entity 
      return {
        ...state,
        entities: state.entities.filter(e => e.entityId !== action.data),
      };

    case 'SITES_SET':
      // Set the entire list of sites
      return {
        ...state,
        sites: action.data.sites,
        sitesMap: action.data.sitesMap,
      };

    case 'SITES_LIMIT':
      // Limit the displayed sites
      return {
        ...state,
        limitedSites: action.data,
      };

    case 'PREFS_SET':
      // Update the user preferences
      return {
        ...state,
        prefs: action.data
      };

    case 'ERRORS_ADD':
      // Append the new error
      return {
        ...state,
        errors: [
          ...state.errors,
          action.error
        ],
      };
    case 'ERRORS_REMOVE':
      // Remove the first error
      return {
        ...state,
        errors: state.errors.slice(1),
      };
    case 'ERRORS_CLEAR':
      // Purge the list
      return {
        ...state,
        errors: [],
      };

    case 'BUSY_SET':
      // Update the busy semaphore and flag
      const busyCount = Math.max(0, state.busyCount + (action.data ? 1 : -1));
      return {
        ...state,
        busyCount,
        isBusy: busyCount > 0
      };

    default:
      return state;
  }
}


/**
 * Utility to wrap event handlers with a try/catch block and display any errors.
 *
 * For example:
 *
 * <Button onClick={withCatch((evt) => { stuff.that.could.fail(); })}>...</Button>
 */
export const withCatch = (callback: any) => (dispatch: any) => async (...args: any) => {
  try {
    await callback(...args);
  }
  catch (err) {
    dispatch(addError(err));
  }
};
