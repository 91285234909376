/**
 * A simple Confirmation Dialog.
 * 
 * <ConfirmDialog title="Really?"
 *   open={confirmOpen}
 *   onClose={() => this.setState({confirmOpen: false})}
 *   onConfirm={() => doTerribleThing()}
 * >
 *   <Typography>
 *     Are you sure? Like really sure?
 *   </Typography>
 * </ConfirmDialog>
 */

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

type Props = {
  title: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  positive?: string;
  negative?: string;
  children?: React.ReactNode;
};

const ConfirmDialog = (props: Props) => {
  const { title, children, open, onClose, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onClose()}
          color="default"
        >
          {props.negative ?? 'No'}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
            onConfirm();
          }}
          color="secondary"
        >
          {props.positive ?? 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
