/**
 * Show errors in popup dialogs
 */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';

import ClipboardIcon from '@material-ui/icons/Assignment';

import { clearErrors, dismissError, StoreState } from '../lib/redux';

const styles = (theme: Theme) => createStyles({
  dialog: {
    '& .MuiDialogContentText-root': {
      whiteSpace: 'pre-wrap',
    },
    '& .MuiDialogTitle-root': {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.dark,
    },
    '& .MuiButton-root': {
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
    }
  },
  dismiss: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.dark,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  errorTextStyle: {
    color: theme.palette.text.primary,
  },
  spacer: {
    flex: '1 1 100%'
  }
});

// Connect to redux
var connector = connect(
  // State
  (state: StoreState) => ({
    errors: state.errors
  }),
  // Dispatch
  {
    dismissError,
    clearErrors
  }
);

type Props = WithStyles<typeof styles> & ConnectedProps<typeof connector>;

const Errors = (props: Props) => {
  const { errors, dismissError, clearErrors, classes } = props;

  if (!errors || (errors.length === 0)) {
    // No errors
    return null;
  }

  // Copying to the clipboard may be disabled
  const can_copy = navigator.clipboard;

  // Take the first error
  const error = errors[0];

  const handleClose = (_event: any, reason: any) => {
    if (reason === 'backdropClick') {
      // Don't close for background clicks
      return false;
    }

    // Dismiss the current error
    dismissError();
  };

  return (
    <Dialog open onClose={handleClose}
      maxWidth="sm" fullWidth
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        {error.title ? error.title : 'Error'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.errorTextStyle }}>
          {error.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          errors.length > 1 &&
           <Button onClick={clearErrors}>
            Dismiss All ({errors.length})
          </Button>
        }
        <div className={classes.spacer}></div>
        <IconButton
          onClick={() => copyToClipboard(error)}
          disabled={!can_copy}
          title="Copy to clipboard"
        >
          <ClipboardIcon />
        </IconButton>
        <Button onClick={dismissError} className={classes.dismiss}>
          Dismiss Error
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Copy the error text to the user's clipboard.
 */
function copyToClipboard(error: any) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(error.text);
  }
}

export default connector(
  withStyles(styles)(Errors)
);
