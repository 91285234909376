/**
 * Main application frame, including navigation bar
 */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppBar, Grid, Toolbar, Typography } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import SignOutIcon from '@material-ui/icons/ExitToApp';

import { Auth } from 'aws-amplify';

import { StoreState, setEntities, setSites, setBusy } from '../lib/redux';
import { Site } from '../lib/types';
import { WithApiProps, withApi } from '../providers';
import logo from '../logo-white-min.svg';
import ConfirmDialog from './ConfirmDialog';
import ResponsiveButton from './ResponsiveButton';
import SiteSelector from './SiteSelector';
import Spinner from './Spinner';
import UserPreferences from './UserPreferences';
import { VECNA_GREY } from '../lib/theme';

// import sites from '../data/sites.json';

// How often to refresh the sites, in milliseconds
const REFRESH_INTERVAL = 10 * 60 * 1000;

// Sort Site by Display Name
const BY_DISPLAYNAME = (a: Site, b: Site) => a.displayName.localeCompare(b.displayName);

const styles = (theme: Theme) => createStyles({
  wrapper: {
    flex: '1 1 auto',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  // The navigation bar should take up a fixed amount at the top
  appBar: {
    flex:'0 0 auto',
    padding: theme.spacing(2, 2, 0, 2),
  },
  toolbar: {
    // height: '40px',
    minHeight: 'unset',
    padding: 0,
    '& .MuiButton-root': {
      marginLeft: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      '& .MuiButton-root': {
        margin: 0,
      }
    }
  },
  logo: {
    flex: '1 1 auto',
    '& img': {
      height: '40px',
      '@media print': {
        // Put a background behind the logo so it will appear when printing.
        backgroundColor: `${VECNA_GREY} !important`,
        padding: theme.spacing(1),
        boxSizing: 'content-box',
      }
    }
  },

  // The container for the children should take up the remaining space
  mainContainer: {
    flex: "1 1 auto",
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
});

// Connect to Redux
const connector = connect(
  // State
  (state: StoreState) => ({
    sites: state.sites,
    isBusy: state.isBusy
  }),
  // Dispatch
  {
    setEntities,
    setSites,
    setBusy
  }
)

type Props = {
  children?: React.ReactNode;
} & WithStyles<typeof styles> & ConnectedProps<typeof connector> & WithApiProps;

type State = {
  confirmSignOut: boolean;
};

class AppFrame extends React.Component<Props, State> {
  intervalId: number | undefined = undefined;
  state: State = {
    confirmSignOut: false
  }

  componentDidMount() {
    // Load the sites
    this.fetchSites();

    // Refresh the entities periodically
    this.intervalId = window.setInterval(this.fetchSites, REFRESH_INTERVAL)
  }

  componentWillUnmount() {
    // Stop refreshing
    clearInterval(this.intervalId);
  }

  /** Fetch the current sites */
  fetchSites = async () => {
    this.props.setBusy(true);
    try {
      const sites = await this.props.api.getSites();

      if (sites) {
        // Sort the sites by display name
        sites.sort(BY_DISPLAYNAME);
        this.props.setSites(sites);
      }
    }
    finally {
      this.props.setBusy(false);
    }
  }

  /** Fetch the current entities */
  fetchEntities = async () => {
    this.props.setBusy(true);
    try {
      const entities = await this.props.api.getEntities();
      if (entities) {
        this.props.setEntities(entities);
      }
    }
    finally {
      this.props.setBusy(false);
    }
  }

  /** Refresh the sites and entities */
  refresh = async () => {
    this.props.setBusy(true);
    try {
      await Promise.all([
        this.fetchEntities(),
        this.fetchSites()
      ]);
    }
    finally {
      this.props.setBusy(false);
    }
  }

  render() {
    const { children, classes, isBusy } = this.props;
    const { confirmSignOut } = this.state;

    return (
      <div className={classes.wrapper}>
        <AppBar position="static" elevation={0} className={classes.appBar} color="transparent">
          <Toolbar className={classes.toolbar}>
            <Grid container alignItems="center">
              <Grid item className={classes.logo}>
                <img src={logo} alt="Vecna Logo" />
              </Grid>
              <Grid item className={classes.logo}>
                <Typography variant="h4">
                  Pivotal Alerts Central
                </Typography>
              </Grid>
              <Grid item>
                <Spinner size={24} active={isBusy} inactive="hide" />
              </Grid>
              <Grid item>
                <SiteSelector />
              </Grid>
              <Grid item>
                <UserPreferences />
              </Grid>
              <Grid item>
                <ResponsiveButton size="small" title="Refresh Data" disabled={isBusy}
                  onClick={() => this.refresh()} endIcon={<RefreshIcon />}
                >
                  Refresh
                </ResponsiveButton>
              </Grid>
              <Grid item>
                <ResponsiveButton size="small" title="Sign Out of Dashboard"
                  onClick={() => this.setState({confirmSignOut: true})} endIcon={<SignOutIcon />}
                >
                  Sign Out
                </ResponsiveButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.mainContainer}>
          {children}
        </div>
        <ConfirmDialog title="Confirm Sign Out"
          open={confirmSignOut}
          onClose={() => this.setState({confirmSignOut: false})}
          onConfirm={() => Auth.signOut()}
        >
          <Typography>
            Are you sure you want to sign out?
          </Typography>
        </ConfirmDialog>
      </div>
    );
  }
}

export default connector(
  withApi(withStyles(styles)(AppFrame))
);
