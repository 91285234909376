/**
 * User SignIn Form
 * 
 * We rely on AWS Amplify for the sign-in form, but we customize it to display a
 * logo.
 * 
 * Note: the sign-in text can be customized thgouh AMPLIFY_LABELS in lib/aws.ts.
 */
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifyStrike,
  AmplifyButton,
} from '@aws-amplify/ui-react';
import Auth from '@aws-amplify/auth';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import { VECNA_GREEN } from '../lib/theme';
import logo from '../logo-white-min.svg';

const styles = (theme: Theme) => createStyles({
  logo: {
    display: 'block',
    margin: theme.spacing(2, 'auto'),
    height: 'auto',
    maxHeight: '25vh',
    maxWidth: '100%',
  },
  container: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& amplify-button': {
      margin: theme.spacing(1, 0),
      display: 'block',
      '& button:hover': {
        backgroundColor: VECNA_GREEN,
        opacity: '1.0',
      }
    }
  },
})

type Props = WithStyles<typeof styles>;

const SignIn = (props: Props) => {
  const { classes } = props;

  // Getteh AWS Auth configuration
  const config = Auth.configure() as any;

  // Check if SAML OAuth is enabled
  const hasOAuth = !!config.oauth;
  // Configure the actual provider name to skip the first screen at the Cognito Hosted UI
  const options = {
    provider: config.oauth?.provider ?? 'oauth'
  };

  return (
    <div className={classes.container}>
      <AmplifyAuthContainer>
        <img src={logo} alt="Vecna Logo" className={classes.logo} />
        { hasOAuth &&
          <div className='App'>
            <AmplifyButton onClick={() => Auth.federatedSignIn(options)}>Login through Vecna Robotics</AmplifyButton>
            <AmplifyButton onClick={() => {
              const $el = document.getElementById('manual-signin');
              if ($el) {
                $el.hidden = false;
              }
            }}>Login Through AWS</AmplifyButton>
            <div id='manual-signin' hidden>
              <AmplifyAuthenticator>
                <AmplifySignIn slot="sign-in" hideSignUp={true}>
                  <div slot="federated-buttons"></div>
                </AmplifySignIn>
              </AmplifyAuthenticator>
            </div>
          </div>
        }
        { !hasOAuth &&
          <AmplifyAuthenticator>
            <AmplifySignIn slot="sign-in" hideSignUp={true}/>
          </AmplifyAuthenticator>
        }
      </AmplifyAuthContainer>
    </div>
  );
}

export default withStyles(styles)(SignIn)
