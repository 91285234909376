import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation_en from './locales/en/translation.json';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    lng: 'en',
    debug: true,
    resources: {
      en: {
        translation: translation_en
      }
    },
    interpolation: {
      // React already escapes the HTML values
      escapeValue: false,
      // Translations use {0} for placeholders
      prefix: '{',
      suffix: '}'
    },
    react: {
      useSuspense: false,
    }
  });


export default i18n;
