/**
 * Expandable table of entity alerts
 */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Button, Container, Typography, Tooltip, Link } from '@material-ui/core';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableCurrentData,
  MUIDataTableOptions
} from 'mui-datatables';
import cx from 'classnames';
import moment from 'moment';

import { Entity, Alert, Site } from '../lib/types';
import { formatTime, formatTimeToday, formatUtc, formatTimeLocale, openSiteTab } from '../lib/utils';
import ConfirmDialog from './ConfirmDialog';
import { StoreState } from '../lib/redux';

// Cell properties to make the column
const MIN_CELL = () => ({ style: { width: '100px', minWidth: '50px' }});
const WIDE_CELL = () => ({ style: { width: '150px', minWidth: '50px' }});

// Entity type priority for sorting
const TYPE_ORDER: Record<string, number> = {
  SYSTEM: 0,
  ROBOT: 1,
  LOCATION: 2,
  OTHER: 3
}

const styles = (theme: Theme) => createStyles({
  title: {
    // Indicate that the title can be clicked on
    cursor: 'pointer',
  },
  table: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    // Be small by default
    flex: '0 0 0%',
    // Animate the opening/closing
    transition: 'flex .5s',
    // When open, take up all available space and allow scrolling
    '& [class^="MUIDataTable-responsiveBase"]': {
      overflow: 'auto'
    },
    '&.open': {
      flex: '1 1 100%',
      overflowX: 'auto',
    },
    '&.suppressed .MuiTableBody-root': {
      background: 'rgba(50, 50, 70, 0.5)',
    },
    '& .MuiTableBody-root .claimed-suppressed': {
      background: 'rgba(50, 50, 70, 0.5)',
    },
    // Pin the table toolbar to the top of the container
    '& .MuiToolbar-root': {
      position: 'sticky',
      top: '0px',
      // Force the background to avoid overlap
      backgroundColor: theme.palette.background.paper,
      // Keep above the other rows, especially the column headers
      zIndex: 1000,
    },
    '& .suppressed': {
      background: 'rgba(50, 50, 70, 0.5)',
    },
  },
  noprint: {
    '@media print': {
      display: 'none',
    }
  }
});

/** Table row */
export type Row = {
  entity: Entity;
  site?: Site;
  site_id: string;
  site_name: string;
  site_url?: string;
  entity_id: string;
  entity_type: string;
  entity_name: string;
  created_at: string;
  messages: string[];
  num_alerts: number;
  claimed_by?: string;
  claimed_at?: string;
  claim_url?: string;
  claimable: boolean;
}

// Connect to Redux
const connector = connect(
  // State
  (state: StoreState) => ({
    prefs: state.prefs,
  }),
  // Dispatch
  {
  }
)

type Props = {
  /** The rows to display in the table */
  rows: Row[];
  /** Are these rows already claimed? */
  claimed: boolean;
   /** Are these alerts suppressed? */
  suppressed?: boolean;
  /** Site lookup table */
  sitesMap: Record<string, Site>;
} & WithStyles<typeof styles> & ConnectedProps<typeof connector>;

type State = {
  /** Is the table opened? */
  open: boolean;
  /** Table sorting */
  sortOrder: {
    name: string;
    direction: 'asc' | 'desc'
  };
  /** IDs of expanded entities */
  expanded: string[];
  /** Saved column filters */
  filters: Record<string, string[]>;
  /** Entity being claimed */
  claimedRow: Row | null;
  /** Is the claim being confirmed? */
  confirmClaim: boolean;
}

class ExpandableAlerts extends React.Component<Props, State> {
  state: State = {
    open: true,
    sortOrder: {
      name: 'created_at',
      direction: 'asc',
    },
    expanded: [],
    filters: {},
    claimedRow: null,
    confirmClaim: false,
  }

  constructor(props: Props) {
    super(props);

    // By default, only show the unclaimed table
    this.state.open = !props.claimed && !props.suppressed;

    // Load the saved column filters
    this.state.filters = this.loadFilters();
  }

  render() {
    const { claimed, rows, suppressed = false } = this.props;
    const { open } = this.state;

    // Prepare the table
    const columns  = this.prepareColumns(rows);
    const expanded = this.findRowsExpanded(rows);

    // Apply the saved column filters
    this.applyFilters(columns);

    // Override table components to hide all but the toolbar when not open
    const components = open ? {} : {
      TableBody: () => null,
      TableHead: () => null,
      TableFilterList: () => null,
    };

    const options: MUIDataTableOptions = {
      // Remove the box shadow
      elevation: 0,
      // Show all the alerts
      pagination: false,
      // No reason to select the rows
      selectableRows: 'none',
      // Don't stack the cells for narrow windows
      responsive: 'standard',
      // Don't allow selecting columns
      viewColumns: false,

      // Manage the sorting
      sortOrder: this.state.sortOrder,
      onColumnSortChange: (name, direction) => this.setState({ sortOrder: { name, direction }}),
      customSort: this.customSorter(columns),

      // Manage the row expansion
      expandableRows: true,
      isRowExpandable: (dataIndex: number) => {
        let row: Row = rows[dataIndex];
        return row.num_alerts > 1;
      },
      expandableRowsOnClick: true,
      rowsExpanded: expanded,
      onRowExpansionChange: this.expandedEntitiesUpdater(rows),
      renderExpandableRow: this.expandedRowRenderer(rows),
      setRowProps(row: any) {
        row = row[0];
        const isClassAllowed = row.alerts.every((alert: any) => alert.suppressed) && !row.claimed_by;
        return {
          className: isClassAllowed ? 'claimed-suppressed' : null
        }
      },

      // Manage the CSV download
      onDownload: this.downloadCsv,
      downloadOptions: {
        filterOptions: {
          // Only download the rows that match the filter
          useDisplayedRowsOnly: true,
        }
      },

      // Manage the filtering
      onFilterChange: this.filterUpdater(columns),
      textLabels: {
        body: {
          noMatch: claimed ? 'There are no claimed alerts.' :
                   suppressed ? 'There are no suppressed alerts.' :
                   <span style={{verticalAlign: 'middle'}}>Yay! You resolved all the alerts <span style={{color: 'gold', fontSize: 'inherit'}}>&#9733;</span></span>,
        },
      }
    };

    // Assemble the table title
    const icon = this.state.open ? <ExpandLessIcon/> : <ExpandMoreIcon/>;
    const table_name = claimed ? "Claimed Alerts" : suppressed ? "Suppressed Alerts" : "Unclaimed Alerts";
    const title =(
      <Typography classes={{ root: this.props.classes.title }} variant="h6" onClick={this.toggleTable}>
        {icon} {table_name} ({rows.length})
      </Typography>
    );

    return (
      <Container className={cx(this.props.classes.table, {'open': open}, {'suppressed': suppressed})} maxWidth={false}>
        <MUIDataTable title={title} options={options} columns={columns} data={rows} components={components} />
        { this.renderConfirm() }
      </Container>
    );
  }

  /**
   * Render the confirm dialog
   */
  renderConfirm = () => {
    const { confirmClaim, claimedRow } = this.state;

    return (
      <ConfirmDialog title="Confirm Claim"
        open={confirmClaim}
        onClose={() => this.setState({ confirmClaim: false })}
        onConfirm={() => this.claimRow(claimedRow)}
      >
        <Typography>
          Are you sure you want to claim {claimedRow?.entity_id} at {claimedRow?.site_name}?
        </Typography>
      </ConfirmDialog>
    );

  }

  /**
   * Toggle the table visibility
   */
  toggleTable = () => {
    this.setState((state) => ({
      open: !state.open
    }));
  }

  /**
   * Filter the rows based on the claimed/unclaimed flag
   *
   * @returns rows matching the claimed/unclaimed flag
   */
  filterRows = (): Row[] => {
    const { claimed, rows } = this.props;
    return rows.filter(r => !!r.claimed_by === claimed);
  }

  /**
   * Prepare the table column definitions
   *
   * Note: Please update downloadEntity() if the column order is changed.
   *
   * @param rows all the data rows
   * @returns table column definitions
   */
  prepareColumns = (rows: Row[]): MUIDataTableColumn[] => {
    const { claimed } = this.props;

    // Get the start of today for timestamp formatting
    const today = this.getToday();

    return [
      { // Raw entity to help with CSV download - see downloadCsv()
        name: 'entity',
        options: {
          display: 'excluded',
          download: false,
          filter: false,
          searchable: false,
        }
      },
      {
        label: 'Claim',
        name: 'claimed_at',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: claimed ? 'excluded' : true,
          filter: false,
          searchable: false,
          customBodyRenderLite: this.renderLite(rows, this.renderClaimButton),
        }
      },
      {
        label: 'Site',
        name: 'site_name',
        options: {
          setCellProps: WIDE_CELL,
          customBodyRenderLite: this.renderLite(rows, this.renderSiteLink),
        }
      },
      {
        label: 'Alert Type',
        name: 'entity_type',
        options: {
          filter: true,
          display: 'excluded',
          setCellProps: MIN_CELL,
          filterOptions: {
            names: Object.keys(TYPE_ORDER),
          },
        }
      },
      {
        label: 'Entity',
        name: 'entity_id',
        options: {
          setCellProps: MIN_CELL,
        }
      },
      {
        label: 'Name',
        name: 'entity_name',
        options: {
          setCellProps: MIN_CELL,
        }
      },
      {
        label: 'Message',
        name: 'messages',
        options: {
          // Show the first message in the main row
          customBodyRenderLite: this.renderLite(rows, r => r.entity.alerts[0]?.message || ''),
          filterOptions: {
            // Find the unique messages for the filter dropdown
            names: this.findUniqueMessages(rows),
          }
        }
      },
      {
        label: 'Created',
        name: 'created_at',
        options: {
          setCellProps: MIN_CELL,
          customBodyRenderLite: this.renderLite(rows, r => this.renderTimestamp(r.created_at, today)),
          filter: false,
          searchable: false,
        }
      },
      {
        label: 'Claimed By',
        name: 'claimed_by',
        options: {
          setCellProps: WIDE_CELL,
          setCellHeaderProps: WIDE_CELL,
          display: claimed ? true : 'excluded',
          filter: claimed,
        }
      },
      {
        label: 'Claimed At',
        name: 'claimed_at',
        options: {
          setCellProps: WIDE_CELL,
          setCellHeaderProps: WIDE_CELL,
          searchable: false,
          display: claimed ? true : 'excluded',
          filter: false,
          customBodyRenderLite: this.renderLite(rows, r => this.renderTimestamp(r.claimed_at, today)),
        }
      },
    ];
  }

  /**
   * Find all the unique alert messages.
   *
   * @param rows Rows in the table
   * @returns sorted array of messages
   */
  findUniqueMessages = (rows: Row[]): string[] => {
    const msgs = new Set<string>();
    rows.forEach(r => r.entity.alerts?.forEach(a => msgs.add(a.message ?? '')))
    return Array.from(msgs).sort();
  }

  /**
   * Build a wrapper to render the expanded row.
   *
   * @param rows all the data rows
   * @returns wrapper for renderExpandableRow
   */
  expandedRowRenderer = (rows: Row[]) => {
    const { claimed, classes } = this.props;

    // Get the start of today for timestamp formatting
    const today = this.getToday();

    return (_rowData: string[], rowMeta: MUIDataTableCurrentData) => {
      const row = rows[rowMeta.dataIndex];
      if (!row?.entity) {
        // Could not find the row
        return null;
      }

      // Give each alert (except the first which is already visible) its own row in the table
      const entity: Entity = row.entity;
      return entity.alerts.slice(1).map((a: Alert) => (
        <tr key={a.id} className={`MuiTableRow-root MuiTableRow-hover ${a.suppressed ? 'suppressed' : ''}`}>
          <td className={cx('MuiTableCell-root MuiTableCell-body', classes.noprint)}></td>
          <td className="MuiTableCell-root MuiTableCell-body" colSpan={claimed ? 5 : 4}></td>
          <td className="MuiTableCell-root MuiTableCell-body">{a.message}</td>
          <td className="MuiTableCell-root MuiTableCell-body">{this.renderTimestamp(a.createdTime, today)}</td>
        </tr>
      ));
    }
  }

  /**
   * Build a wrapper to update the list of expanded entities when the row
   * expansion changes.
   * @param rows all the data rows
   * @returns wrapper for onRowExpansionChange
   */
  expandedEntitiesUpdater = (rows: Row[]) => {
    return (_current: any, all: MUIDataTableCurrentData[], _expanded: any) => {
      this.setState({
        expanded: all.map(row => rows[row.dataIndex].entity.entityId)
      })
    }
  }

  /**
   * Determine which rows in the table should be expanded based on which entities have been expanded.
   *
   * @param rows all the data rows
   * @returns list of row indexes that whould be expanded
   */
  findRowsExpanded = (rows: Row[]): number[] => {
    const { expanded } = this.state;
    return rows.map((r, idx) => expanded.includes(r.entity.entityId) ? idx : -1).filter(idx => idx !== -1);
  }

  /**
   * Wrapper that finds the original data row and renders a field.
   *
   * @param rows all the rows in the table
   * @param callback function that renders the field
   * @returns wrapper for customBodyRenderLite
   */
  renderLite = (rows: Row[], callback: (row: Row) => any) => {
    return (dataIndex: number, _rowIndex: number) => {
      const row = rows[dataIndex];
      if (!row) {
        return null;
      }

      return callback.call(null, row);
    };
  }


  /**
   * Find the start of the current day
   *
   * @returns the start of the day, as a Moment
   */
  getToday = () => moment().startOf('date');

  /**
   * Render a timestamp with a tooltip based on the user preferences
   */
  renderTimestamp = (time: any, today: moment.Moment) => {
    const { prefs } = this.props;

    // Format the time based on the user preferences
    const text = prefs.todayTime
      ? formatTimeToday(time, today)
      : formatTime(time);

    return (
      <Tooltip title={formatTimeLocale(time)}>
        <span>{text}</span>
      </Tooltip>
    );
  }

  /**
   * Assemble the CSV download data
   *
   * @param buildHead callback to build CSV header
   * @param buildBody callback to build CSV rows
   * @param columns column definitions
   * @param data row data
   * @returns CSV string, or false to prevent download
   */
  downloadCsv = (buildHead: (columns: any) => string, buildBody: (data: any) => string, columns: any, data: any): string => {
    return (
      // Header
      buildHead(columns)
      // plus all the expanded entities - the entity is in column 0 of the row data
      + data.map((r: any) => this.downloadEntity(buildBody, r.data[0])).join('\r\n')
    );
  }

  /**
   * Assemble the CSV download data for a single entity
   *
   * Note: the column order should match the columns in prepareColumns().
   *
   * @param buildBody callback to build CSV rows
   * @param entity current entity
   * @returns CSV string
   */
  downloadEntity = (buildBody: (data: any) => string, entity: Entity): string => {
    return buildBody(
      entity.alerts.map((a, i) => ({
        index: i,
        data: [
          null, // Entity
          entity.siteId,
          entity.entityType,
          entity.entityId,
          entity.entityName,
          formatUtc(a.createdTime),
          a.message || '',
          null, // num_alerts
          entity.claim?.claimedUser,
          formatUtc(entity.claim?.claimedTime),
        ]
      }))
    );
  }

  /**
   * Render the Claim button for the row
   *
   * @param row table row
   * @returns Rendered button
   */
  renderClaimButton = (row: Row) => {
    if (row.claimed_by) {
      // Don't show a button if the entity is already claimed
      return null;
    }

    // Not all alerts can be claimed
    if (!row.claim_url || !row.claimable) {
      let tooltip = '';
      if (!row.claim_url) {
        // The site was not configured, so show a disabled button with a tooltip
        tooltip = `Site ${row.site_id} is not configured`;
      }
      else if (!row.claimable) {
        // The entity has no claimable alerts - perhaps these are IoT generated alerts
        tooltip = `These alerts cannot be claimed`;
      }

      // Note: the extra <span> is to work around tooltips not appearing on disabled elements.
      return (
        <Tooltip title={tooltip} className={this.props.classes.noprint}>
          <span><Button variant="contained" color="secondary" disabled={true} >Claim</Button></span>
        </Tooltip>
      );
    }

    // All good - show the button
    return (
      <Button className={this.props.classes.noprint} variant="contained" color="secondary" onClick={e => this.claimClicked(e, row)}>Claim</Button>
    );
  }

  /**
   * Handle a click on the Claim button
   *
   * @param e the mouse click event
   * @param row the Row being claimed
   */
  claimClicked = (e: React.MouseEvent, row: Row) => {
    const { prefs } = this.props;

    // Don't pass the click on to the row
    e.stopPropagation();

    if (prefs.confirmClaim) {
      // Confirm before claiming
      this.setState({
        claimedRow: row,
        confirmClaim: true,
      });
    }
    else {
      // Claim immediately
      this.claimRow(row);
    }
  }

  /**
   * Claim the entity after confirmation
   */
  claimRow = (row: Row | null) => {
    if (!row) {
      return;
    }

    // Send the operator to Vecna to claim the Entity
    if (row.claim_url) {
      openSiteTab(row.claim_url, row.site?.siteId ?? 'Unknown');
    }

    // Reset the state
    this.setState({
      claimedRow: null,
      confirmClaim: false
    });
  }

  /**
   * Render the Site Name as a link
   *
   * @param row table row
   * @returns Rendered link
   */
  renderSiteLink = (row: Row) => {
    const site_url = row.site_url;
    if (site_url) {
      // Add a link to the site
      return (
        <Link href="#" color="inherit" onClick={
          (e) => {
            // Don't actually follow the link
            e.preventDefault();
            // Don't click on the row
            e.stopPropagation();
            // Open the site tab
            openSiteTab(site_url, row.site?.siteId ?? 'Unknown');
          }
        }>{row.site_name}</Link>
      );
    }
    else {
      // The site is not configured - just show the name
      return row.site_name;
    }
  }

  /**
   * Build a wrapper to sort the rows by entity type priority (except when
   * actually sorting by the entity column)
   *
   * @param columns column definitions
   * @returns wrapper for customSort
   */
  customSorter = (columns: MUIDataTableColumn[]): (data: any[], colIndex: number, order: string) => any[] => {
    // Figure which column has the entity type
    const type_index = columns.findIndex(c => c.name === 'entity_type');

    // Build a comparator to always keep the types in the same order
    const type_cmp = this.typeCompare(type_index);

    return (data: any[], colIndex: number, order: string) => {
      // Build a comparator to sort by the column and order
      const col_cmp = this.sortCompare(colIndex, order);

      if (colIndex === type_index) {
        // User is already sorting by entity type
        return data.sort(col_cmp);
      }
      else {
        // Sort by entity type first, then the column
        return data.sort((a, b) => type_cmp(a, b) || col_cmp(a, b));
      }
    };
  }

  /**
   * Build a comparator to sort by the entity type
   *
   * @param idx column index
   * @returns comparator
   */
  typeCompare = (idx: number) => {
    return (a: any, b: any): number =>  {
      const a_type = a.data[idx] ?? 'OTHER';
      const b_type = b.data[idx] ?? 'OTHER';
      return (TYPE_ORDER[a_type] ?? 99) - (TYPE_ORDER[b_type] ?? 99);
    }
  }

  /**
   * Build a comparator to sort the data based on the column and order
   *
   * @param idx column index
   * @param order sort direction (asc, desc)
   * @returns comparator
   */
  sortCompare = (idx: number, order: string) => {
    return (a: any, b: any): number => {
      const aData = a.data[idx] ?? '';
      const bData = b.data[idx] ?? '';
      return ((typeof aData.localeCompare === 'function' ? aData.localeCompare(bData) : aData - bData) * (order === 'asc' ? 1 : -1));
    };
  }

  /**
   * Build the local storage key for a type of data
   *
   * @param data_type type of data (filters, etc)
   * @returns key into local storage
   */
  makeStorageKey = (data_type: string): string => {
    // The value are stored per-table
    const table_type = this.props.claimed ? 'claimed' : 'unclaimed' ;

    return `alerts.${table_type}.${data_type}`;
  }

  /**
   * Load the filter list values from local storage
   *
   * @returns filter lists
   */
  loadFilters = (): Record<string, string[]> => {
    // Fetch the saved JSON value from local storage
    const saved = localStorage.getItem(this.makeStorageKey('filters'));
    return saved ? JSON.parse(saved) : {};
  }

  /**
   * Apply the filter lists to the columns
   *
   * @param columns column definitions
   */
  applyFilters = (columns: MUIDataTableColumn[]) => {
    const { filters } = this.state;

    for (const column of columns) {
      if (!column.options) {
        // Make sure the column has options
        column.options = {}
      }

      // Apply the filter list from saved state
      column.options.filterList = filters[column.name];
    }
  }

  /**
   * Build a wrapper to manage column filters
   *
   * @param columns column definitions
   * @returns wrapper to update the filter state
   */
  filterUpdater = (columns: MUIDataTableColumn[]) => {
    // Get the key for the local storage
    const key = this.makeStorageKey('filters');

    // Build the wrapper
    return (_changedColumn: any, filterList: string[][], _type: any, _changedColumnIndex: number, _displayData: any) => {
      // New filter state
      const filters: Record<string, string[]> = {};

      // Add each column's filter list
      columns.forEach((c, idx) => filters[c.name] = filterList[idx]);

      // Save in local storage for next time
      localStorage.setItem(key, JSON.stringify(filters));
      // Save in component state for now
      this.setState({ filters })
    };
  }
}

export default connector(
  withStyles(styles)(ExpandableAlerts)
);
