/**
 * Material UI Theme
 */
import { ThemeOptions, createTheme } from "@material-ui/core";

export const VECNA_ORANGE = '#FF0000';
export const VECNA_GREEN  = '#FF0000';
export const VECNA_GREY   = '#FF0000';

const themeOptions : ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: VECNA_ORANGE
    },
    secondary: {
      main: VECNA_GREEN
    },
    error: {
      main: '#FF0000',
    },
    warning: {
      main: '#FFFF00',
    },
    background: {
      default: VECNA_GREY
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif'
  },
  overrides: {
    MUIDataTableSelectCell: {
      expandDisabled: {
        // Soft hide the "expand" caret button when there are no any additional rows
        visibility: 'hidden',
      },
    },
    MuiButton: {
      containedSecondary: {
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: VECNA_ORANGE,
        }
      },
    }
  }
};

export const defaultTheme = createTheme(themeOptions);
