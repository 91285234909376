/**
 * Access to the REST API
 * 
 * For example, with a ClassComponent:
 *   import { withApi, WithApiProps } from '../providers';
 *   type Props = {
 *     // Local properties
 *    } & WithApiProps;
 *   
 *   class Example extends react.Component<Props, State> {
 *      async render() {
 *        const data = this.props.api.getEntities();
 *      
 *        return (
 *           ...
 *        );
 *      }
 *   }
 * 
 *   export default withApi(Example);
 * 
 * or a FunctionComponent:
 * 
 *   import { useApi } from '../providers';
 *   export default async Example(props) {
 *      const { api } = useApi();
 * 
 *      const data = await api.getEntities();
 *      return (
 *        ...
 *      );
 *   }
 */

 import React, { useContext } from 'react';
 import { useStore } from 'react-redux';
 
 import hoistNonReactStatics from 'hoist-non-react-statics';
 
 import RestAPI from '../lib/api';
 import getDisplayName from './getDisplayName';
 
 /*
  * Properties added by withApi()
  */
 export interface WithApiProps {
   /** Interface to the REST API */
   api: RestAPI;
 }
 
 /** Context for user verification */
 export const ApiContext = React.createContext<WithApiProps>({} as WithApiProps);
 
 /**
  * Provides RestAPI for functional components
  * 
  * @returns REST API interface
  */
 export const useApi = (): WithApiProps => useContext(ApiContext);
 
 /**
  * Wraps a component to provide the WithApiProps
  * 
  * @param WrappedComponent the component to wrap
  * @returns the wrapper component
  */
 export function withApi<P extends WithApiProps, R = Omit<P, keyof WithApiProps>>(
   WrappedComponent: React.ComponentType<P>
 ): React.ComponentType<R> {
   // Wrap the component with the consumer
   const Wrapper = (props: R) => (
     <ApiContext.Consumer>
       {context => (
         <WrappedComponent
           {...(props as any)}
           api={context.api}
         />
       )}
     </ApiContext.Consumer>
   );
 
   // Copy static methods from WrappedComponent to Wrapper
   hoistNonReactStatics(Wrapper, WrappedComponent);
 
   // Make the component name prettier for debugging
   if (process.env.NODE_ENV !== 'production') {
     Wrapper.displayName = `WithApi(${getDisplayName(WrappedComponent)})`;
   }
 
   return Wrapper;
 }
 
 type Props = {
   /** Child components to render in the body */
   children?: React.ReactNode;
 };
 
 /**
  * Component to provide the REST API
  * 
  * Note: This should only be used in index.jsx.
  */
 export default function ApiProvider(props: Props) {
   const api = new RestAPI(useStore());
   return  (
     <ApiContext.Provider value={{api}}>
       {props.children}
     </ApiContext.Provider>
   );
 }
 