
/**
 * Site Selection dialog and top-bar button
 */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Button, Checkbox, FormControlLabel, FormGroup, Radio, TextField, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle }  from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import FilterIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import CheckedIcon from '@material-ui/icons/CheckBox';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import cx from 'classnames';

import { Site } from '../lib/types';
import { StoreState, limitSites } from '../lib/redux';

const styles = (theme: Theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  siteWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    overflowY: 'hidden',
    '&.disabled': {
      opacity: '50%',
      pointerEvents: 'none',
    },
    paddingLeft: theme.spacing(4),
  },
  spacer: {
    flex: '1 1 100%',
  },
  searchMore: {
    whiteSpace: 'nowrap',
  },
  siteList: {
    display: 'flex',
    flex: '1 1 100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    overflowY: 'auto',
    marginTop: theme.spacing(1),
    '& .MuiFormControlLabel-root': {
      flex: '1 0 45%',
      marginLeft: 0,
    },
    minHeight: '25vh',
    maxHeight: '25vh',
  },
});

// Connect to Redux
const connector = connect(
  // State
  (state: StoreState) => ({
    sites: state.sites,
    limitedSites: state.limitedSites,
  }),
  // Dispatch
  {
    limitSites,
  }
)

type Props = {
  // sites: Site[]
} & WithStyles<typeof styles> & ConnectedProps<typeof connector>;

type State = {
  open: boolean;
  checked: Record<string, boolean>;
  showAll: boolean;
  search: string | null;
};

class SiteSelector extends React.Component<Props, State> {
  state: State = {
    open: false,
    checked: {},
    showAll: true,
    search: null,
  }

  render() {
    const { classes, limitedSites } = this.props;

    // Are the sites currently limited?
    const filtered = !!limitedSites?.length;

    return (
      <>
        <Tooltip title="Focus on Sites">
          <Button className={classes.wrapper}
            color={filtered ? 'primary' : undefined }
            variant={filtered ? 'contained' : undefined}
            onClick={() => this.openDialog()} endIcon={<FilterIcon />}
          >
            Sites ({limitedSites?.length || 'All'})
          </Button>
        </Tooltip>
        { this.renderDialog() }
      </>
    );
  }

  /**
   * Render the site selection dialog
   */
  renderDialog() {
    const { classes, sites } = this.props;
    const { open, checked, showAll, search } = this.state;

    if (!open) {
      // Nothing to display
      return null;
    }

    // Search the displayed sites
    const searched = sites.filter(s => this.matchesSearch(s, search));
    // Count the limited sites that are not displayed
    const more     = sites.filter(s => checked[s.siteId] && !this.matchesSearch(s, search)).length;

    // Can the Update button be clicked?
    const canUpdate = showAll || sites.some(s => checked[s.siteId]);

    return (
      <Dialog
        id="dlg-site-limiter"
        open={open}
        onClose={() => this.closeDialog()}
        aria-labelledby="select-dialog"
        fullWidth={true}
      >
        <DialogTitle id="select-dialog">Select Sites</DialogTitle>
        <DialogContent className={classes.wrapper}>
          <FormControlLabel label={'Show all sites'} control={
            <Radio checked={showAll} onChange={() => this.setState({showAll: true})} />
          } />
          <FormControlLabel label={'Focus on these sites'} control={
            <Radio checked={!showAll} onChange={() => this.setState({showAll: false})} />
          } />
          <div className={cx(classes.siteWrapper, {'disabled': showAll})}>
            { search !== null &&
              <TextField
                onChange={(e) => this.updateSearch(e.target.value)}
                onKeyDown={this.searchKeyDown}
                value={search}
                autoFocus={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: more ? ( <div className={classes.searchMore}>+ {more} more</div> ) : null,
                }}
              />
            }
            <FormGroup className={classes.siteList}>
              {
                searched.map((s) => (
                  <FormControlLabel key={s.siteId} label={s.displayName} control={
                    <Checkbox checked={checked[s.siteId] ?? false} onChange={this.checkedUpdater(s)} />
                  } />
                ))
              }
            </FormGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Search sites">
            <span>
              <IconButton
                disabled={showAll}
                onClick={() => this.toggleSearch()}
              >
                <SearchIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Select all">
            <span>
              <IconButton
                disabled={showAll}
                onClick={() => this.toggleAll(true)}
              >
                <CheckedIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Select none">
            <span>
              <IconButton
                disabled={showAll}
                onClick={() => this.toggleAll(false)}
              >
                <UncheckedIcon />
              </IconButton>
            </span>
          </Tooltip>
          <div className={classes.spacer}></div>
          <Button
            variant="contained"
            onClick={() => this.closeDialog()}
            color="default"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!canUpdate}
            onClick={() => {
              this.updateSites();
              this.closeDialog();
            }}
            color="secondary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * Open the selection dialog
   */
  openDialog = () => {
    const { limitedSites } = this.props;

    // Which sites are currently checked?
    const checked: Record<string, boolean> = {}
    limitedSites?.forEach(site_id => checked[site_id] = true);

    // Initialize the state
    this.setState({
      open: true,
      checked,
      showAll: !limitedSites?.length,
      search: null,
    });
  }

  /**
   * Build a wrapper to update the state when a site checkbox is checked.
   * 
   * @param site element site
   * @returns Checkboc onChange callback
   */
  checkedUpdater = (site: Site) => {
    const { checked } = this.state;

    // Build the wrapper for the checkbox onChange
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        checked: {
          ...checked,
          [site.siteId]: e.target.checked
        }
      });
    };
  }

  /**
   * Check or uncheck all the visible sites
   * 
   * @param value Checked state for all the checkboxes
   */
  toggleAll = (value: boolean) => {
    const { sites } = this.props;
    const { search, checked } = this.state;

    // Set the state of all the visible checkboxes
    const toggled: Record<string, boolean> = { ...checked };
    sites.filter(s => this.matchesSearch(s, search)).forEach(s => toggled[s.siteId] = value);

    this.setState({ checked: toggled });
  }

  /**
   * Handle a keypress event in the search field
   * 
   * @param event keyboard event
   */
  searchKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      // Dismiss the search field
      this.toggleSearch();

      // ... but not the entire dialog
      event.stopPropagation();
    }
  }

  /**
   * Toggle the search field
   */
  toggleSearch = () => {
    const { search } = this.state;
    this.setState({
      search: search === null ? '' : null
    })
  }

  /**
   * Update the search text for quick-filtering the sites
   * 
   * @param search search text
   */
  updateSearch = (search: string) => {
    this.setState({ search: search.toLocaleLowerCase() });
  }


  /**
   * Does the site match the search string?
   * 
   * @param site site to check
   * @param search search text that should be in site info
   * @returns true if the site matches the search string
    */
  matchesSearch = (site: Site, search: string | null) : boolean => {
    return site.displayName.toLocaleLowerCase().includes(search ?? '');
  }

  /**
   * Update the limited sites
   */
  updateSites = () => {
    const { sites, limitSites } = this.props;
    const { showAll, checked } = this.state;

    if (showAll) {
      // Show unlimited sites
      limitSites(null);
    }
    else {
      // Limit to the checked sites
      limitSites(sites.filter(s => checked[s.siteId]).map(s => s.siteId));
    }
  }

  /**
   * Close the selection dialog
   */
  closeDialog = () => {
    this.setState({
      open: false,
      checked: {},
      search: null,
    });
  }
}

export default connector(
  withStyles(styles)(SiteSelector)
);
