
/**
 * User Preferences dialog and top-bar button
 */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Button, Switch, FormControlLabel } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle }  from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';

import { Preferences } from '../lib/types';
import { StoreState, updatePrefs } from '../lib/redux';
import ResponsiveButton from './ResponsiveButton';

const styles = (theme: Theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
});

// Connect to Redux
const connector = connect(
  // State
  (state: StoreState) => ({
    prefs: state.prefs,
  }),
  // Dispatch
  {
    updatePrefs,
  }
)

type Props = WithStyles<typeof styles> & ConnectedProps<typeof connector>;

type State = {
  /** Is the dialog open? */
  open: boolean;
};

class UserPreferences extends React.Component<Props, State> {
  state: State = {
    open: false,
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <ResponsiveButton size="small" className={classes.wrapper}
          title="Update User Preferences"
          onClick={() => this.openDialog()} endIcon={<SettingsIcon />}>
          Preferences
        </ResponsiveButton>
        { this.renderDialog() }
      </>
    );
  }

  /**
   * Render the site selection dialog
   */
  renderDialog() {
    const { classes, prefs } = this.props;
    const { open } = this.state;

    if (!open) {
      // Nothing to display
      return null;
    }

    return (
      <Dialog
        id="dlg-user-prefs"
        open={open}
        onClose={() => this.closeDialog()}
        aria-labelledby="title-user-prefs"
        fullWidth={true}
      >
        <DialogTitle id="title-user-prefs">User Preferences</DialogTitle>
        <DialogContent className={classes.wrapper}>
          <FormControlLabel label={'Use short Entity IDs'} control={
            <Switch checked={prefs.shortEntityId} onChange={(e) => this.savePref('shortEntityId', e.target.checked)} />
          } />
          <FormControlLabel label={'Only show the time for today'} control={
            <Switch checked={prefs.todayTime} onChange={(e) => this.savePref('todayTime', e.target.checked)} />
          } />
          <FormControlLabel label={'Confirm before claiming'} control={
            <Switch checked={prefs.confirmClaim} onChange={(e) => this.savePref('confirmClaim', e.target.checked)} />
          } />
          <FormControlLabel label={'Beep for new unclaimed Entities'} control={
            <Switch checked={prefs.newEntityBeep} onChange={(e) => this.savePref('newEntityBeep', e.target.checked)} />
          } />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => this.closeDialog()}
            color="secondary"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * Open the selection dialog
   */
  openDialog = () => {
    // Initialize the state
    this.setState({
      open: true,
    });
  }

  /**
   * Save a preference
   */
  savePref = (key: keyof Preferences, value: any) => {
    const { updatePrefs, prefs } = this.props;

    updatePrefs({
      ...prefs,
      [key]: value
    });
  }

  /**
   * Close the selection dialog
   */
  closeDialog = () => {
    this.setState({
      open: false,
    });
  }
}

export default connector(
  withStyles(styles)(UserPreferences)
);
