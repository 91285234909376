/**
 * AWS Amplify configuration
 * 
 * See: https://docs.amplify.aws/lib/q/platform/js/
 */

import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import { Hub, I18n } from '@aws-amplify/core';
import { Store } from 'redux';

import { connect, disconnect } from '@giantmachines/redux-websocket';

import { setAwsUser, StoreState, Action } from './redux';

/**
 * Add the Authentication header to all Amplify API requests
 */
const add_auth = async () => {
  const session = await Auth.currentSession()
  const token = session.getIdToken().getJwtToken();
  return {
    Authorization: `Bearer ${token}`
  };
};

// Copy some variables out of the environment (check .env files)
const {
  REACT_APP_AWS_REGION           : AWS_REGION,
  REACT_APP_COGNITO_USER_POOL_ID : COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_CLIENT_ID    : COGNITO_CLIENT_ID,
  REACT_APP_API_ENDPOINT         : API_ENDPOINT,
  REACT_APP_OAUTH_DOMAIN         : OAUTH_DOMAIN,
  REACT_APP_OAUTH_REDIRECT       : OAUTH_REDIRECT,
  REACT_APP_OAUTH_PROVIDER       : OAUTH_PROVIDER,
} = process.env;

// Setup the Amplify config
const configs: any = {
  Auth: {
    region: AWS_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_CLIENT_ID,
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "data",
        endpoint: API_ENDPOINT,
        custom_header: add_auth,
      },
    ],
  },
};

// Add in the OAuth config, if complete
if (OAUTH_DOMAIN) {
  if (OAUTH_REDIRECT && OAUTH_PROVIDER) {
    console.log('aws:  OAuth env is OK');

    configs.Auth.oauth = {
      domain: OAUTH_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid'],
      redirectSignIn: OAUTH_REDIRECT,
      redirectSignOut: OAUTH_REDIRECT,
      responseType: 'code',
      provider: OAUTH_PROVIDER,
    }
  }
  else {
    console.error('aws: OAuth env is incomplete', {
      OAUTH_DOMAIN, OAUTH_REDIRECT, OAUTH_PROVIDER
    });
  }
}

// Text labels for the sign up form
// See: https://docs.amplify.aws/lib/utilities/i18n/q/platform/js/
const AMPLIFY_LABELS = {
  en: {
    'Sign in to your account': 'Welcome to Vecna Alerts'
  }
};

/**
 * Handle a Cognito user signing in
 */
async function handleSignIn(store: Store<StoreState, Action>) {
  const state = store.getState();
  if (state.awsUser) {
    console.log('handleSignIn: User is already signed in');
    return;
  }

  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    console.log('handleSignIn: User is null');
    return;
  }

  // Store the new user
  store.dispatch(setAwsUser(user));

  // Connect to the WebSocket
  if (process.env.REACT_APP_WS_ENDPOINT) {
    store.dispatch(connect(process.env.REACT_APP_WS_ENDPOINT) as any)
  }
}

/**
 * Handle a Cognito user explicitly signing out
 */
async function handleSignOut(store: Store<StoreState, Action>) {
  // Purge the session
  store.dispatch(setAwsUser(null));

  // Disconnect from the WebWocket
  store.dispatch(disconnect() as any);

  // Go to the home page after sign-out to clear the URL
  window.location.href = '/';
}

/**
 * Confiure AWS Amplify
 */
export default function configureAws(store: Store<StoreState, Action>) {
  // Configure Amplify 
  Auth.configure(configs.Auth);
  API.configure(configs.API);

  // Customize the text labels
  I18n.putVocabularies(AMPLIFY_LABELS);

  // Listen for AWS authentication events (eg: sign-in, sign-out)
  Hub.listen('auth', async (data) => {
    const { payload } = data;
    switch (payload.event) {
      case 'signIn':
      case 'cognitoHostedUI':
        handleSignIn(store);
        break;

      case 'signOut':
        handleSignOut(store);
        break;

      default:
        console.log('auth event', payload);
        break;
    }
  });

  // Listen for events from the AmplifyAuthenticator
  Hub.listen('UI Auth', async (data) => {
    const { payload } = data
    console.log('UI Auth', payload);
    if (payload?.event === 'AuthStateChange') {
      switch (payload.message) {
        case 'signedin':
          // User is already logged in
          handleSignIn(store);
          break;
        default:
          break;
      }
    }
  });
}
