/**
 * Application bootstrap
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';

import 'typeface-roboto';

import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import configureAws from './lib/aws';
import reduxReducer from './lib/redux';
import ApiProvider from './providers/ApiProvider';
import websocketReduxMiddleware from './lib/websocket';

import './i18n';

// Help TypeScript with some typing related to Redux
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create the Redux store used to manage global state
const store = createStore(
  reduxReducer,
  composeEnhancers(applyMiddleware(...websocketReduxMiddleware(), thunk)),
);

// Configure AWS Amplify
configureAws(store);

// Load the App
ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ApiProvider>
        <App />
      </ApiProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
