/**
 * Main entry point for the application
 */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { MuiThemeProvider } from '@material-ui/core/styles';

import AppFrame from './components/AppFrame';
import SignIn from './components/SignIn';
import AlertTables from './components/AlertTables';
import Errors from './components/Errors';
import { defaultTheme } from './lib/theme';
import { addError, StoreState } from './lib/redux';

import './App.css';

// Connect to redux
const connector = connect(
  // State
  (state: StoreState) => ({
    awsUser: state.awsUser,
  }),
  // Dispatch
  {
    addError
  }
);

type Props = ConnectedProps<typeof connector>;

class App extends React.Component<Props> {
  // Note an error from a child component
  static getDerivedStateFromError(_error: any) {
    return {
      hasError: true
    };
  }

  // Setup an error boundary to handle errors from child components
  componentDidCatch(error: any, _info: any) {
    this.props.addError(error);
  }

  // Update the browser tab name of the document to include current number of unclaimed rows in the table
  updateUnclaimedTableRowCount = (count: number) => {
    const title = count && count > 0 ? `(${count}) PACE` : "PACE";
    document.title = title;
  };

  render() {
    if (!this.props.awsUser) {
      // Not logged in - go to the sign-in page
      return (
        <MuiThemeProvider theme={defaultTheme}>
          <SignIn />
        </MuiThemeProvider>
      );
    }

    return (
      <MuiThemeProvider theme={defaultTheme}>
        <Errors />
        <AppFrame>
          <AlertTables updateTitleWithUnclaimedAlertsCount={this.updateUnclaimedTableRowCount} />
        </AppFrame>
      </MuiThemeProvider>
    );
  }
}

export default connector(App);
