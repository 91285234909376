import moment from 'moment';

/** Common timestamp format  (13:04 Tu 21st)*/
export const TS_FORMAT = 'HH:mm dd Do';
/** Common timestamp format - no time  (Tu 21st) */
export const TS_FORMAT_HIDE_TIME = 'dd Do';
/** Localized timestamp format (Tue, Sep 21, 2021 1:04 PM) */
export const TS_FORMAT_LOCALE = 'llll';

/**
 * Format the timestamp, but hide the time portion if it was before today.
 * 
 * @param time any sort of parseable time (epoch millis, ISO string, Date)
 * @returns timestamp in the common or short format, or the empty string if the time is missing
 */
export function formatTimeToday(time: any, today: moment.Moment): string {
  if (!time) {
    return '';
  }

  const ts = moment(time);
  return (
    ts.isBefore(today)
      // Hide the time for earlier timestamps
      ? ts.format(TS_FORMAT_HIDE_TIME)
      // Show the time for today
      : ts.format(TS_FORMAT)
  );
}

/**
 * Format the timestamp in the common format.
 * 
 * @param time any sort of parseable time (epoch millis, ISO string, Date)
 * @returns timestamp in the common format, or the empty string if the time is missing
 */
export function formatTime(time: any): string {
  return time ? moment(time).format(TS_FORMAT) : '';
}

/**
 * Format the timestamp as an ISO8601 string (2021-08-31T17:31:00.123Z)
 * 
 * @param time any sort of parseable time (epoch millis, ISO string, Date)
 * @returns ISO8601 timestamp string, or empty string if the time is missing
 */
export function formatUtc(time: any): string {
  return time ? moment(time).toISOString() : '';
}

/**
 * Format the timestamp in the user's locale
 * 
 * @param time any sort of parseable time (epoch millis, ISO string, Date)
 * @returns locale-specific timestamp, or empty string if the time is missing
 */
export function formatTimeLocale(time: any): string {
  return time ? moment(time).format(TS_FORMAT_LOCALE) : '';
}

/**
 * Open a URL in a new tab
 * 
 * @param url URL to open
 */
export function openNewTab(url: string) {
  // Open the new tab, but try to avoid a security issue with '_blank'
  // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
  const tab = window.open(url, '_blank', 'noopener,noreferrer');
  if (tab) {
    tab.opener = null;
  }
}

/**
 * Open a URL in a site-specific tab
 * 
 * Note: Browser behavior is inconsistent regarding reusing tabs and user navigation.
 * 
 * @param url URL to open
 * @param site_id Site ID
 */
export function openSiteTab(url: string, site_id: string) {
  window.open(url, `site-${site_id}`);
}

/**
 * Loads a value from Local Storage
 * 
 * @param key unique key into local storage
 * @returns data from storage, or null is none exists
 */
export function loadLocal(key: string): any {
  const data = localStorage.getItem(key);
  return data === null ? null : JSON.parse(data);
}

/**
 * Saves a value into Local Storage
 * 
 * @param key unique key into local storage
 * @param data to be stored
 */
export function saveLocal(key: string, data: any) {
  localStorage.setItem(key, JSON.stringify(data));
}
