/**
 * Button wrapper that hides the child content when the screen is too small.
 * 
 * Will display the "title" in a Tooltip.
 * Start and end icons are always displayed.
 * 
 * For example:
 * 
 *  <ResponsiveButton size="small" title="Do something"
 *    onClick={...} endIcon={<SomeIcon />}
 *  >
 *    Click me!
 *  </ResponsiveButton>
 */
import React from "react";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  button: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '& .MuiButton-endIcon': {
        margin: 0
      },
      '& .MuiButton-startIcon': {
        margin: 0
      }
    }
  },
  label: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export default function ResponsiveButton(props: any) {
  const classes = useStyles();

  const { children, className, title, ...other } = props

  return (
    <Tooltip title={title}>
      <span>
        <Button
          {...other}
          className={cx(classes.button, className)}
        >
            <span className={classes.label}>{children}</span>
        </Button>
      </span>
    </Tooltip>
  );
}
