/**
 * A busy indicator
 * 
 * <Spinner active={busy} size={36} />
 */

import React from 'react';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';

import cx from 'classnames';

import { VECNA_ORANGE, VECNA_GREEN } from '../lib/theme';

// Default size, in px
const SPINNER_SIZE = 48;
const SPACING      = SPINNER_SIZE / 3;
const DOT_SIZE     = 12;

// How much to rotate the dots to match the logo
const OFFSET_DEG = -15;

// Convert Degrees to radians
const rads = (degs: number) => degs * Math.PI / 180.0;

/**
 * Build the box-shadow CSS value for alternating dots
 * 
 * @param color Color of the dot
 * @param open Is the dot open
 * @param ...pos clock positions for the dots
 * @returns box-shadow CSS string
 */
const shadows = (color: string, open: boolean, ...pos: number[]): string => {
  return pos.map(p => {
    const angle = rads(90 + OFFSET_DEG - 30 * p);

    const x =      SPACING * Math.cos(angle);
    const y = -1 * SPACING * Math.sin(angle);
    const w = open ? 0 : -DOT_SIZE / 3;

    return `${x}px ${y}px 0 ${w}px ${color}`;
  }).join(', ');
}

const styles = (theme: Theme) => createStyles({
  wrapper: {
    width: SPINNER_SIZE,
    height: SPINNER_SIZE,
    position: 'relative',
    // transition: 'visibility .5s',
  },
  dot: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    // Center the dot
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // Pause the animation when not active
    '&.paused': {
      animationPlayState: 'paused'
    },
  },
  circle: {
    borderRadius: '50%',
    background: 'white',
    animation: '$spinnerCircle .5s linear alternate infinite',
  },
  square: {
    animation: '$spinnerSquare .5s linear alternate infinite',
  },
  '@keyframes spinnerCircle': {
    '0%':   { boxShadow: shadows(VECNA_ORANGE, true,  12, 4, 8) },
    '100%': { boxShadow: shadows(VECNA_ORANGE, false, 12, 4, 8) },
  },
  '@keyframes spinnerSquare': {
    '0%':   { boxShadow: shadows(VECNA_GREEN, false, 2, 6, 10) },
    '100%': { boxShadow: shadows(VECNA_GREEN, true,  2, 6, 10) },
  }
});

type Props = {
  /** Is the spinner active? */
  active: boolean;
  /** What to do when inactive */
  inactive?: 'pause' | 'hide' | 'remove';
  /** Size (px) of the spinner (default: 48) */
  size?: number;
} & WithStyles<typeof styles>;

const Spinner = (props: Props) => {
  const { active=false, inactive='hide', size=SPINNER_SIZE, classes } = props;

  if (!active && (inactive === 'remove')) {
    // Remove the spinner element
    return null;
  }

  const style: any = {
    visibility: (active || inactive === 'pause') ? 'visible': 'hidden',
    width:  `${size}px`,
    height: `${size}px`,
  }

  if (size !== SPINNER_SIZE) {
    // Scale the spinner to the desired size
    style.transform = `scale(${size / SPINNER_SIZE })`;
  }

  return (
    <div className={classes.wrapper} style={style}>
      <div className={cx(classes.dot, classes.circle, {'paused': !active})}></div>
      <div className={cx(classes.dot, classes.square, {'paused': !active})}></div>
    </div>
  );
}

export default withStyles(styles)(Spinner);
